<script>
import appConfig from "@/app.config";
export default {
  page: {
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {},
  data() {
    return {
      salary_items: [],
      SalaryItems: {},
      page: 1,
      limit: 20,
      tot_pages: 0,
      searchSalaryItems: null,
      SearchMode: false,
      emps: [],
      dedcutionTot: 0,
      total_salary: 0,
      total_deduction: 0,
      total: 0,
      branch_id: null,
      payData: {},
      currentAssets: [],
      addAsset: [{}],
      totalSalary: 0,
      date: "",
      descr: "",
      emp_id: null,
      selectedEmpIndex: null,

      // appprices:[]
    };
  },
  methods: {
    deleteOption(index) {
      this.addAsset.splice(index, 1);
    },
    generateSalaries() {
      const month = this.date.split("-")[1];
      // const year = this.date.split("-")[0];
      // console.log({ year, month });
      this.http
        .post("monthly-salary-details/generate-salaries", {
          branch_id: this.branch_id,
          date: this.date,
          month,
          descr: this.descr,
        })
        .then(() => {
          console.log("done");
          this.date = "";
          this.descr = "";
          this.get(this.page);
          setTimeout(() => {
            document.querySelector(".btn-close").click();
          }, 200);
        });
    },
    pay(app, index) {
      this.currentAssets = [];
      this.selectedEmpIndex = index;
      this.emp_id = app.id;
      this.http
        .post("salary-items/salary-data", {
          code: app.id,
          branch_id: this.branch_id,
        })
        .then((resp) => {
          this.currentAssets = resp.data;
          // this.currentAssets = resp.data[1];
          this.totalSalary = resp.totalSalary.toLocaleString();
          console.log("done", this.currentAssets);
        });
      this.payData = app;
      console.log(this.payData);
    },
    paySalary() {
      // console.log(this.addAsset);
      this.http
        .post("salary-items/pay", {
          emp_id: this.emp_id,
          details: this.addAsset,
        })
        .then((resp) => {
          console.log("done");
          this.totalSalary = resp.data;
          this.totalSalary = this.totalSalary.toLocaleString();
          this.addAsset = [{}];
          this.emps.forEach((emp, index) => {
            if (index == this.selectedEmpIndex) {
              emp.totalSalary = resp.data;
            }
          });
        });
    },
    search(searchSalaryItems) {
      this.SearchMode = true;
      this.tot_pages = 0;
      if (searchSalaryItems == "") {
        this.get(this.page);
        return;
      }
      this.http
        .post("emps/search", {
          search: this.searchSalaryItems,
          limit: this.limit,
          page: this.page,
          col: "code",
        })
        .then((res) => {
          this.emps = res.data;
          this.emps.forEach((emp) => {
            console.log(emp);
          });
        });
    },
    cancelSearchMode() {
      this.searchSalaryItems = "";
      this.SearchMode = false;
      this.get(this.page);
    },

    get(page) {
      console.log(page);
      this.http
        .post("emps/calculate", {
          limit: this.limit,
          page: page,
          branch_id: this.branch_id,
        })
        .then((res) => {
          this.page = page;
          this.tot_pages = Math.ceil(res.tot / this.limit);
          this.emps = res.data;
          //   console.log(
          //     res.data[0]?.monthly_salary_details[0]?.salary_item
          //       ?.salary_item_degrees[0]?.value
          //   );
          //   for (
          //     let resultIndex = 0;
          //     resultIndex < res.data.length;
          //     resultIndex++
          //   ) {
          //     for (
          //       var dedactionIndex = 0;
          //       dedactionIndex < res.data[resultIndex]?.salary_deductions?.length;
          //       dedactionIndex++
          //     ) {
          //       this.dedcutionTot +=
          //         res.data[resultIndex].salary_deductions[dedactionIndex].amount;
          //       console.log(
          //         "*********************",
          //         res.data[resultIndex].salary_deductions[dedactionIndex].amount
          //       );
          //     }
          //     var tot = 0;
          //     for (
          //       let i = 0;
          //       i < res.data[resultIndex]?.monthly_salary_details?.length;
          //       i++
          //     ) {
          //       for (
          //         let j = 0;
          //         j <
          //         res.data[resultIndex]?.monthly_salary_details[i]?.salary_item
          //           ?.salary_item_degrees.length;
          //         j++
          //       ) {
          //         tot += parseInt(
          //           res.data[resultIndex]?.monthly_salary_details[i]?.salary_item
          //             .salary_item_degrees[j]?.value
          //         );
          //         console.log("--------------------", tot);
          //         this.emps[resultIndex].total = tot; //   for (let degreesIndex = 0; degreesIndex < result.monthly_salary_details[i].salary_item.salary_item_degrees[j].length; degreesIndex++) {
          //         this.emps[resultIndex].dedactionTotal = this.dedcutionTot;
          //         this.total =
          //           this.emps[resultIndex].total -
          //           this.emps[resultIndex].dedactionTotal;
          //         this.emps[resultIndex].total_salary = this.total;
          //       }
          //     }
          //   }
          //   console.log(this.emps);
        });
    },
  },
  created() {
    const current_user = JSON.parse(localStorage.currentUser);
    this.branch_id = current_user.branch_id;
    this.get(1);
  },
};
</script>

<template>
  <PageHeader
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
    :title="$t('salaryItems.title')"
  />
  <div class="row mb-3">
    <div class="col-6">
      <div class="search-box chat-search-box" style="width: 250px">
        <div class="position-relative">
          <input
            :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
            @keyup="search(searchSalaryItems)"
            type="text"
            class="form-control fa-lg text-light"
            :placeholder="$t('salaryItems.search')"
            v-model="searchSalaryItems"
            style="background-color: #2a3042 !important"
          />
          <i class="bx bx-search-alt search-icon text-light"></i>
          <button
            @click="cancelSearchMode()"
            v-if="SearchMode"
            class="btn-close text-light"
            style="position: absolute; top: 12px; left: 250px"
          ></button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#payModal"
        class="btn btn-primary float-end my-2"
      >
        {{ $t("emps.genrate_salaries") }}
      </button>
    </div>
  </div>

  <table :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="table">
    <thead>
      <tr
        class="text-light text-center"
        style="background-color: #2a3042 !important"
      >
        <th scope="col">{{ $t("emps.id") }}</th>
        <th scope="col">{{ $t("emps.empjobtitle") }}</th>
        <th scope="col">{{ $t("emps.branch") }}</th>
        <th scope="col">{{ $t("emps.code") }}</th>
        <th scope="col">{{ $t("emps.name") }}</th>
        <th scope="col">{{ $t("emps.tel") }}</th>
        <th scope="col">{{ $t("emps.degree") }}</th>
        <!-- <th scope="col">{{ $t("emps.monthly") }}</th> -->
        <th scope="col">{{ $t("emps.paid") }}</th>
        <th scope="col">{{ $t("emps.remaining") }}</th>
        <th scope="col">{{ $t("emps.created") }}</th>
        <th scope="col">{{ $t("emps.updated") }}</th>
        <th scope="col">{{ $t("emps.operations") }}</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(app, index) in emps" :key="app" class="text-center">
        <td>{{ index + 1 }}</td>
        <td>{{ app.emp_job_title?.name }}</td>
        <td>{{ app.branch?.name }}</td>
        <td>{{ app.code }}</td>
        <td>{{ app.name }}</td>
        <td>{{ app.tel }}</td>
        <td>{{ app.degree }}</td>
        <td>{{ app?.paid?.toLocaleString() }}</td>
        <td>{{ app?.total?.toLocaleString() }}</td>
        <!-- <td>{{ app?. }}</td> -->
        <td>{{ app?.created.split("T")[0] }}</td>
        <td>{{ app.updated.split("T")[0] }}</td>

        <td class="d-flex justify-content-around">
          <!-- <router-link :to="'/employee_details/' + app.id">
            <a
              class="btn btn-primary me-2"
              href="javascript: void(0);"
              role="button"
              >{{ $t("popups.details") }}</a
            >
          </router-link> -->
          <button
            @click="pay(app, index)"
            data-bs-toggle="modal"
            data-bs-target="#addModal"
            class="btn btn-success"
          >
            {{ $t("emps.pay") }}
          </button>
        </td>
      </tr>
    </tbody>
  </table>
  <!-- end  table -->
  <!--Start Pay Modal-->
  <div
    class="modal fade"
    id="payModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="generateSalaries()">
          <div class="modal-header">
            <div class="d-flex justify-content-around">
              <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
              <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.genrate_salaries") }}
              </h5>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row d-flex justify-content-center">
              <div class="row">
                <label for="#date">{{ $t("emps.date") }}</label>
                <input
                  type="month"
                  v-model="date"
                  id="date"
                  class="form-control mb-2"
                />
              </div>
              <div class="row">
                <label for="#descr">{{ $t("emps.descr") }}</label>
                <textarea
                  class="form-control mb-2 mx-1"
                  v-model="descr"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button type="submit" class="btn btn-primary">
              {{ $t("emps.genrate") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Pay Modal-->

  <!--Start Add Modal-->
  <div
    class="modal fade"
    id="addModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
    :dir="$i18n.locale == 'ar' ? 'rtl' : ''"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <form @submit.prevent="paySalary()">
          <div class="modal-header">
            <div class="d-flex justify-content-around">
              <!-- <h5 class="modal-title" id="exampleModalLabel">
                {{ $t("emps.pay") }}
              </h5> -->
              <h5 class="modal-title" id="exampleModalLabel">
                <span>{{ $t("emps.total") }} : </span>
                {{ totalSalary }}
              </h5>
            </div>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              style="margin: 0"
            ></button>
          </div>

          <div class="modal-body">
            <div class="row d-flex align-items-center">
              <div class="col">
                <label for="asset_id">{{
                  $t("popups.chose_salary_options")
                }}</label>
              </div>
              <div class="col d-flex justify-content-end">
                <button
                  v-if="addAsset.length < 3"
                  type="button"
                  @click="addAsset.push({})"
                  class="btn float-end"
                >
                  <span
                    class="bx bxs-plus-circle float-end fa-2x"
                    style="color: #2a3042 !important"
                  ></span>
                </button>
              </div>
            </div>
            <div v-for="(asset, index) in addAsset" :key="asset">
              <div class="row my-3">
                <div class="d-flex justify-content-between">
                  <select
                    v-model="asset.asset_id"
                    class="form-select mb-2"
                    id="asset_id"
                    :placeholder="$t('emps.account')"
                  >
                    <option
                      v-for="assets in currentAssets"
                      :key="assets"
                      :value="assets?.id"
                    >
                      {{ assets?.name }}
                    </option>
                  </select>
                  <input
                    v-model="asset.value"
                    type="number"
                    class="form-control mb-2 mx-1"
                    :placeholder="$t('emps.amount')"
                  />
                  <span class="mx-2">
                    <button
                      class="btn btn-danger"
                      @click="deleteOption(index)"
                      v-if="index > 0"
                    >
                      <i class="bx bx-trash"></i>
                    </button>
                    <button v-else disabled class="btn btn-danger">
                      <i class="bx bx-trash"></i>
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
            <button
              :disabled="
                !addAsset[0]?.hasOwnProperty('asset_id') &&
                !addAsset[0]?.hasOwnProperty('value')
              "
              type="submit"
              class="btn btn-primary"
            >
              {{ $t("emps.pay") }}
            </button>
            <button
              data-bs-dismiss="modal"
              type="button"
              class="btn btn-danger"
            >
              {{ $t("popups.cancel") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <!--End Add Modal-->

  <!--   Apps  Pagination     -->
  <ul
    v-if="tot_pages >= 20"
    class="pagination pagination-rounded justify-content-center mb-2"
  >
    <li class="page-item" :class="{ disabled: page == 1 }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page - 1)"
        aria-label="Previous"
      >
        <i class="mdi mdi-chevron-left"></i>
      </a>
    </li>
    <li
      class="page-item"
      :class="{ active: p == page }"
      v-for="p in tot_pages"
      :key="p"
    >
      <a class="page-link" href="javascript: void(0);" @click="get(p)">{{
        p
      }}</a>
    </li>

    <li class="page-item" :class="{ disabled: page == tot_pages }">
      <a
        class="page-link"
        href="javascript: void(0);"
        @click="get(page + 1)"
        aria-label="Next"
      >
        <i class="mdi mdi-chevron-right"></i>
      </a>
    </li>
  </ul>
  <!--end-->
</template>
